import getReps from './getReps';

export default function initializeData () {
  const exercises = [
    { id: 'A01', name: 'Biceps Dumbbell Curls', reps: 50, max: 120, increase: 5, days: 1, notes: 'Alternate arms' },
    { id: 'A02', name: 'Reverse Barbell Curls', reps: 25, max: 120, increase: 2, days: 1, notes: 'Both arms together' },
    { id: 'A03', name: 'Side Dumbbell Curls', reps: 10, max: 120, increase: 1, days: 1, notes: 'Alternate arms' },
    { id: 'A04', name: 'Side Dumbbell Curls', reps: 10, max: 120, increase: 1, days: 3, notes: 'Both arms together' },
    { id: 'A05', name: 'Standing Chest Flyes', reps: 5, max: 120, increase: 1, days: 2, notes: '' },
    { id: 'A06', name: 'Shoulder Presses', reps: 15, max: 120, increase: 1, days: 2, notes: 'Alternate arms' },
    { id: 'A07', name: 'Front Raises', reps: 10, max: 120, increase: 1, days: 2, notes: 'Alternate arms' },
    { id: 'A08', name: 'Wrist Flexors', reps: null, max: null, increase: null, days: null, notes: 'To failure' },
    { id: 'A09', name: 'Wrist Rotations', reps: null, max: null, increase: null, days: null, notes: 'To failure' },
    { id: 'A10', name: 'Shoulder Lunge', reps: 10, max: 120, increase: 1, days: 2, notes: 'Alternate arms and legs' },
    { id: 'A11', name: 'Push-ups', reps: 3, max: 120, increase: 1, days: 3, notes: '' },
    { id: 'A12', name: 'Resisted Push-ups', reps: 2, max: 120, increase: 1, days: 3, notes: '' },
    { id: 'A13', name: 'Sit-ups', reps: 3, max: 120, increase: 1, days: 2, notes: '' },
    { id: 'A14', name: 'Squats', reps: 10, max: 120, increase: 1, days: 2, notes: '' },
    { id: 'A15', name: 'Side Bends', reps: 15, max: 120, increase: 1, days: 2, notes: '' },
  ];

  // Loop and calculate the reset day
  exercises.forEach(item => {
    for (let x = 1; x <= item.max * item.days; x++) {
      item.current = x - 1;
      getReps(item);
      if (item.reset) {
        break;
      }
    }
  });

  console.info('INFO: Prepared data for the first time.');
    
  return exercises;
}