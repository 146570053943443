import React from 'react';
import { css, cx } from 'emotion';

export default function ItemCard ({ item, className }) {
  return (
    <div className={cx(css`
      display: flex;
      padding: 4px 10px;
      cursor: default;
    `, className)}>
      <div className={css`
        flex: none;
        width: 20px;
        margin-right: 1em;
        padding-left: 4px;
        color: #a3a3a3;
      `}>L{item.level}</div>
      <div className={css`
        flex: none;
        width: 40px;
        margin-right: 1em;
        text-align: right;
        font-weight: bold;
      `} title={`Max ${item.max} / Reset ${item.reset} / Current ${item.current}`}>{item.reps || '—'}</div>
      <div className={css`
        flex: auto;
      `}>{item.name}</div>
      <div className={css`
        font-size: 0.8rem;
        color: #656565;
        text-align: right;
      `}>{item.notes}</div>
    </div>
  );
}