import { useState, useEffect, useCallback } from 'react';
import firebase from './firebase';
import initializeData from './initializeData';

const db = firebase.firestore();

const store = {
  set: (value) => {
   return db.collection('records').doc('user1').set(value, { merge: true });
  },
  get: async () => {
    const doc = await db.collection('records').doc('user1').get();
    if (doc.exists) {
      return doc.data();
    }
    return {};
  }
};

const initialState = { day: 1, done: 0 };

export default function useFirestore () {
  const [exercises, setExercises] = useState([]);
  const [state, setState] = useState({});

  const setDay = useCallback((day) => {
    setState(state => ({ ...state, day }));
  }, []);

  const setDone = useCallback((day) => {
    setState(state => ({ ...state, done: day || state.day }));
  }, []);

  const reset = useCallback(() => {
    setExercises(initializeData());
    setState(state => ({ ...initialState, day: state.done + 1, done: state.done }));
  }, []);

  useEffect(() => {
    if (exercises && exercises.length > 0) {
      store.set({ exercises });
    } else {
      store.get().then(data => {
        setExercises(data.exercises || initializeData());
      });
    }
  }, [exercises]);

  useEffect(() => {
    if (state && state.day) {
      store.set({ state });
    } else {
      store.get().then(data => {
        if (data.state && data.state.done && data.state.done !== data.state.day) {
          data.state.day = data.state.done + 1;
        }
        setState(data.state || { ...initialState });
      });
    }
  }, [state]);

  return { ...state, exercises, setDay, setDone, reset };
}
