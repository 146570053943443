import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDPK7KT8laf_sb2DZvZ7HZL-3LshJSc5xg",
  authDomain: "meeko-app.firebaseapp.com",
  databaseURL: "https://meeko-app.firebaseio.com",
  projectId: "meeko-app",
  storageBucket: "meeko-app.appspot.com",
  messagingSenderId: "813829678385",
  appId: "1:813829678385:web:53c7f02636b1ba7409fc10"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
