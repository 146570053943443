import React, { useState, useEffect } from 'react';
import { css } from 'emotion';

import getReps from '../helpers/getReps';
import useFirestore from '../helpers/useFirestore';
import ItemCard from './ItemCard';

export default function App () {
  const { exercises, day, done, setDay, setDone, reset } = useFirestore();
  const [list, setList] = useState([]);

  const goBack = (e) => {
    if (day > 1) {
      setDay(day - 1);
    } else {
      setDay(1);
    }
  };

  const goNext = (e) => {
    setDay(day + 1);
  };

  const goReset = (e) => {
    reset();
  };

  const markDone = (e) => {
    setDone(day);
  };

  // useEffect(() => {
  //   if (done && done !== day) {
  //     setDay(done + 1);
  //   }
  // }, []);

  useEffect(() => {
    const list = [];
    if (exercises) {
      exercises.forEach(item => {
        item.current = day - 1;
        const reps = getReps(item);
        const level = item.reset ? Math.ceil(day / item.reset) : 1;
        list.push({ ...item, reps, level });
      });
    }
    setList(list);
  }, [exercises, day]);

  return (
    <main className={css`
      display: flex;
      flex-direction: column;
      height: 100vh;
    `}>
      <ItemCard item={{ level: 'evel', reps: 'Reps', name: 'Exercise', notes: `Day ${day}` }} className={css`
        flex: none;
        font-size: 0.85rem;
        border-bottom: 1px solid #d3d3d3;
        margin-bottom: 5px;
        background-color: #f3f3f3;
      `} />
      <div className={css`
        flex: auto;
        overflow-y: auto;
      `}>
        {list.map(item => <ItemCard key={item.id} item={item} />)}
      </div>
      <nav className={css`
        flex: none;
        display: flex;
        padding: 16px;
      `}>
        <button onClick={goReset}>Reset</button>
        <button onClick={goBack}>Back</button>
        <button onClick={goNext}>Next</button>
        <span className={css`flex: auto;`} />
        <button onClick={markDone} className={css({
          boxShadow: '0 0 6px 1px ' + (day === done ? 'green' : day > done ? 'blue' : 'transparent'),
        })}>{day === done ? 'Done day' : day > done ? 'Finish day' : 'Revert to day'} {day}</button>
      </nav>
      <footer className={css`
        flex: none;
        padding: 1rem;
      `}>
        <div className={css`
          font-size: 10px;
          color: #a6a6a6;
          > a {
            color: #9a9a9a;
          }
        `}>Icons made by <a href="https://www.flaticon.com/authors/vectors-market" title="Vectors Market">Vectors Market</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
      </footer>
    </main>
  );
}
