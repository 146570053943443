export default function getReps (item) {
  const reps = item.reps + (item.increase * Math.floor(item.current / item.days));
  if (item.reset && item.current > item.reset) {
    item.current = item.current % item.reset;
    return getReps(item); 
  } else if (reps > item.max) {
    item.reset = item.current;
    item.current = item.current % item.reset;
    return getReps(item);
  }
  return reps;
}